<template>
    <div style="min-height: 100%;background-color: #EEEEEC;">
        <div class="header">
            <i @click="go('/')" class="el-icon-arrow-left"></i>
            <span>智能点数</span>
            <span>离线</span>
        </div>
        <!-- <div class="card">
            <el-row>
                <el-col :span="18">
                    <div class="title">已验标:5头</div>
                    <div class="comment">测试能繁母猪保险，离线待上传0头</div>
                </el-col>
                <el-col :span="6">
                    <div class="next">下一步<i class="el-icon-arrow-right"></i></div>
                </el-col>
            </el-row>
        </div> -->
        <div class="pig-container">
            <!-- <el-row :gutter="20">
                <el-col :span="12" v-for="(val,key) in pigs" :key="key">
                    <div class="pig-box">
                        <i class="el-icon-delete corner"></i>
                        <img :src="val.face" alt="">
                        <div class="info">
                            <div>
                                <span>第{{ key+1 }}头</span>
                                <span class="id">{{ val.id }}</span>
                            </div>
                            <div class="time">{{ val.date }}</div>
                        </div>
                    </div>
                </el-col>
            </el-row> -->
            <div class="pig-box">
                <!-- <i class="el-icon-delete corner"></i> -->
                <img :src="pig.face" alt="">
                <div class="info">
                    <div>
                        <!-- <span>第{{ key+1 }}头</span> -->
                        <span class="id">{{ pig.id }}</span>
                    </div>
                    <div class="time">{{ formatDate(new Date(),'yyyy-MM-dd hh:mm:ss') }}</div>
                </div>
            </div>
            
        </div>
        <div class="btn" @click="go('/dotFinish')">点数</div>
    </div>
</template>
<script>
import { formatDate } from '@/utils/utils'
export default{
    data() {
        return {
            pig:{
                face:require('@/assets/img/图层 3 拷贝 3.png'),
                id:'13581248',
                date:'2024-03-08 14:35'
            },
            pigs:[{
                face:require('@/assets/img/pig_scan.png'),
                id:'13581248',
                date:'2024-03-08 14:35'
            },
            {
                face:require('@/assets/img/pig_scan.png'),
                id:'13581248',
                date:'2024-03-08 14:35'
            },
            {
                face:require('@/assets/img/pig_scan.png'),
                id:'13581248',
                date:'2024-03-08 14:35'
            },
            {
                face:require('@/assets/img/pig_scan.png'),
                id:'13581248',
                date:'2024-03-08 14:35'
            },
            {
                face:require('@/assets/img/pig_scan.png'),
                id:'13581248',
                date:'2024-03-08 14:35'
            }]
        }
    },
    methods:{
        formatDate(date,format){
            return formatDate(date,format)
        }
    }
}
</script>
<style lang="stylus" scoped>
.header{
    background-color: #04C694;
    height: 2.5rem;
    line-height: 2.5rem;
    color: #fff;
    display flex;
    justify-content space-between;
    align-items center
    box-sizing border-box;
    padding 0 20px;
    i{
        font-size 26px;
        font-weight 600
    }
}

.pig-container{
    padding: 0 10px
}

.pig-box{
    margin 10px 0;
    position relative;
    background-color #fff;
    padding-bottom 10px;
    img{
        width 100%;
    }

    .info{
        text-align left ;
        padding 0 10px; 
        .id{
            float right;
            color #029A64;
        }
        .time{
            font-size 14px;
            margin-top 5px;
        }
    }
}

.btn{
    position fixed;
    z-index 2;
    bottom 50px;
    background-color #04C694;
    color #fff;
    width 70px;
    height 70px;
    line-height 70px;
    border-radius 50%;
    left 50%
    transform translateX(-50%);
    // cursor pointer;
}

.card{
    background-color #fff
    margin 10px;
    border-radius 5px;
    .title{
        font-size 18px;
        border-left 3px solid #04C694;
        text-align left;
        text-indent 5px;
        margin 10px 10px;
    }
    .comment{
        font-size 14px;
        margin-bottom 10px;
        text-align left;
        text-indent 20px;
    }
    .next{
        margin-top 10px;
        color #04C694;
        i{
            font-size 16px;
            font-weight 600;
        }
    }
}

.corner{
    position absolute;
    right 5px;
    top 5px;
    font-size 20px;
    z-index 1;
    color #fff;
}
</style>
